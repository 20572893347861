import React, { Component } from 'react';
import { NativeModules, NativeEventEmitter, DeviceEventEmitter, Platform } from 'react-native';
import Polyline from '@mapbox/polyline';

export const ShareToFeed = {
    send: async (message, imagePath) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await NativeModules.ECInstagram.send(message, imagePath);
                resolve(response);
            } catch(e) {
                reject(e);
            }
        })
    }
}
